<template>
  <div class="voucher_config_view">
    <el-tabs v-model="curTab" @tab-click="handleTabClick">
      <el-tab-pane label="数据库链接配置" name="1">
        <div class="tab_box">
          <el-row class="status_box" type="flex">
            <el-col class="name" :span="2">数据库连接配置</el-col>
            <el-col class="status">
              <span class="wa" v-if="form.dataStatus === 0"><i class="el-icon-question"></i>连接失败</span>
              <span class="su" v-else-if="form.dataStatus == 1"><i class="el-icon-success"></i>已连接</span>
              <span class="lo" v-else-if="form.dataStatus == 2"><i class="el-icon-loading"></i>连接中</span>
              <span class="lo" v-else-if="form.dataStatus == 3"><i class="el-icon-warning"></i>未连接</span>
            </el-col>
          </el-row>
          <el-form
            :rules="{
              dataUrl: [{ required: true, message: '必填', trigger: 'blur' }],
              dataName: [{ required: true, message: '必填', trigger: 'blur' }],
              dataUser: [{ required: true, message: '必填', trigger: 'blur' }],
              dataPass: [{ required: true, message: '必填', trigger: 'blur' }]
            }"
            class="cus_form"
            ref="form"
            :model="form"
            label-width="130px"
          >
            <el-form-item label="数据库链接地址" prop="dataUrl">
              <el-input v-model="form.dataUrl" placeholder="请输入数据库链接地址" clearable></el-input>
            </el-form-item>
            <el-form-item label="数据库名称" prop="dataName">
              <el-input v-model="form.dataName" placeholder="请输入数据库名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="数据库用户名" prop="dataUser">
              <el-input v-model="form.dataUser" placeholder="请输入数据库用户名" clearable></el-input>
            </el-form-item>
            <el-form-item label="数据库密码" prop="dataPass">
              <el-input v-model="form.dataPass" placeholder="请输入数据库密码" show-password clearable autocomplete="new-password"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button resset @click="handleReset">取消</el-button>
              <el-button type="primary" @click="handleSubmit">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="进项发票配置" name="2">
        <div class="tab_box">
          <el-row class="status_box" type="flex">
            <el-col class="name" :span="2">摘要配置</el-col>
          </el-row>
          <el-row class="status_box">
            <el-radio-group v-model="radio">
              <el-radio :label="1">按凭证模板名称</el-radio>
              <el-radio :label="2">自定义摘要名称</el-radio>
            </el-radio-group>
          </el-row>
          <el-row class="tpl_box">
            <el-checkbox-group v-model="checkedList" @change="handleCheckboxChange">
              <el-checkbox v-for="v in cItems" :label="v.label" :key="v.value">{{ v.label }}</el-checkbox>
            </el-checkbox-group>
            <div class="tip">示例：模板名称（此处模板名称为采购）+勾选项（此处勾选发票类型、发票号码），则摘要显示为：采购-增值税专票-12345678。</div>
          </el-row>
        </div>
        <div class="tab_box mt_50">
          <el-row class="status_box" type="flex">
            <el-col class="name" :span="2">凭证日期</el-col>
          </el-row>
          <el-row class="status_box">
            <el-radio-group v-model="radio">
              <el-radio :label="1">自定义日期</el-radio>
              <el-radio :label="2">当前日期</el-radio>
              <el-radio :label="3">发票日期</el-radio>
              <el-radio :label="4">对应期间最后一天</el-radio>
            </el-radio-group>
          </el-row>
          <el-row class="tpl_box">
            <el-radio-group v-model="radio">
              <el-radio :label="5">按自然月</el-radio>
              <el-radio :label="6">按季度</el-radio>
            </el-radio-group>
          </el-row>
        </div>
        <div class="tab_box mt_50">
          <el-row class="status_box" type="flex">
            <el-col class="name" :span="2">凭证合并规则</el-col>
          </el-row>
          <el-row class="status_box">
            <el-radio-group v-model="radio">
              <el-radio :label="1">合并规则</el-radio>
            </el-radio-group>
          </el-row>
          <el-row class="tpl_box">
            <el-radio-group v-model="radio">
              <el-radio :label="5">会计科目相同合并</el-radio>
            </el-radio-group>
          </el-row>
        </div>
        <div class="tab_box" style="text-align: center">
          <el-button resset @click="$refs.form.resetFields()">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="销项发票配置" name="3">
        <div class="tab_box">
          <el-row class="status_box" type="flex">
            <el-col class="name" :span="2">摘要配置</el-col>
          </el-row>
          <el-row class="status_box">
            <el-radio-group v-model="radio">
              <el-radio :label="1">按凭证模板名称</el-radio>
              <el-radio :label="2">自定义摘要名称</el-radio>
            </el-radio-group>
          </el-row>
          <el-row class="tpl_box">
            <el-checkbox-group v-model="checkedList" @change="handleCheckboxChange">
              <el-checkbox :label="凭证模板名称" :key="1">凭证模板名称 <el-input readonly value="原材料采购" disabled style="width: 100px"></el-input></el-checkbox>
              <el-checkbox :label="发票类型" :key="2">发票类型</el-checkbox>
              <el-checkbox :label="发票号码" :key="3">发票号码</el-checkbox>
              <el-checkbox :label="发票日期" :key="4">发票日期</el-checkbox>
              <el-checkbox :label="帐期" :key="5">帐期</el-checkbox>
            </el-checkbox-group>
            <div class="tip">示例：固定文本（此处输入“原材料采购”）+勾选项（此处勾选发票类型、发票号码），则摘要显示为：原材料采购-增值税专票-12345678</div>
          </el-row>
        </div>
        <div class="tab_box mt_50">
          <el-row class="status_box" type="flex">
            <el-col class="name" :span="2">凭证日期</el-col>
          </el-row>
          <el-row class="status_box">
            <el-radio-group v-model="radio">
              <el-radio :label="1">自定义日期</el-radio>
              <el-radio :label="2">当前日期</el-radio>
              <el-radio :label="3">发票日期</el-radio>
              <el-radio :label="4">对应期间最后一天</el-radio>
            </el-radio-group>
          </el-row>
        </div>
        <div class="tab_box mt_50">
          <el-row class="status_box" type="flex">
            <el-col class="name" :span="2">凭证合并规则</el-col>
          </el-row>
          <el-row class="status_box">
            <el-radio-group v-model="radio">
              <el-radio :label="1">合并规则</el-radio>
            </el-radio-group>
          </el-row>
          <el-row class="tpl_box">
            <el-radio-group v-model="radio">
              <el-radio :label="5">会计科目相同合并</el-radio>
            </el-radio-group>
          </el-row>
        </div>
        <div class="tab_box" style="text-align: center">
          <el-button resset @click="$refs.form.resetFields()">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </el-tab-pane> -->
      <el-tab-pane label="公共配置" name="4">
        <div class="tab_box">
          <el-row class="status_box" type="flex">
            <el-col class="name" :span="2">公共配置</el-col>
          </el-row>
          <el-row class="status_box">
            <el-checkbox v-model="form.matchCustomer" :true-label="1" :false-label="0">智能匹配客户/供应商信息</el-checkbox>
          </el-row>
          <el-row class="tpl_box">
            <div class="tip">若往来单位中无发票客户或供应商信息，系统将自动存储客户或供应商至往来单位；生成凭证时，辅助核算勾选往来单位，则智能匹配客户或供应商。</div>
          </el-row>
          <el-row class="status_box">
            <el-checkbox v-model="form.autoPush" :true-label="1" :false-label="0">自动推送往来单位、项目至财务软件</el-checkbox>
          </el-row>
          <el-row class="status_box mt_50">
            <el-radio-group v-model="form.useInvoiceRemark">
              <el-radio :label="0">不使用发票备注信息</el-radio>
              <el-radio :label="1">使用发票备注信息</el-radio>
            </el-radio-group>
          </el-row>
          <el-row class="tpl_box">
            <el-col class="mb_24">
              <el-checkbox style="width: 100px" :checked="true" :disabled="form.useInvoiceRemark == 0">关联项目</el-checkbox>
              <label class="la_W">项目匹配规则</label>
              <el-input :disabled="form.useInvoiceRemark == 0" v-model="form.matchProjectReg" placeholder="请输入项目匹配规则"></el-input>
              &nbsp;&nbsp;
              <!-- <el-radio-group v-model="radio">
                <el-radio :label="1">匹配项目编码</el-radio>
                <el-radio :label="2">匹配项目名称</el-radio>
              </el-radio-group> -->
            </el-col>
            <!-- <el-col class="mb_24">
              <el-checkbox style="width: 100px">关联客户&nbsp;&nbsp;</el-checkbox>
              <label class="la_W">客户匹配规则</label>
              <el-input placeholder="请输入客户匹配规则"></el-input>
            </el-col>
            <el-col class="mb_24">
              <el-checkbox style="width: 100px">关联供应商</el-checkbox>
              <label class="la_W">供应商匹配规则</label>
              <el-input placeholder="请输入供应商匹配规则"></el-input>
            </el-col> -->
          </el-row>
        </div>
        <div class="tab_box" style="text-align: center">
          <el-button resset @click="$refs.form.resetFields()">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
const initForm = { matchCustomer: 1, dataUrl: '', dataName: '', autoPush: 1, dataUser: '', dataPass: '', dataStatus: '', useInvoiceRemark: 0 };
const loopCount = 9;
import { configGet, configSave } from '@/service/voucher';
export default {
  components: {},
  data() {
    return {
      timer: null,
      timeCount: 0,
      tmpShowCount: 1,
      checkedList: [],
      cItems: [
        { value: 1, label: '凭证模板名称' },
        { value: 2, label: '发票类型' },
        { value: 3, label: '发票号码' },
        { value: 4, label: '发票日期' },
        { value: 5, label: '帐期' }
      ],
      radio: 1,
      curTab: '1',
      form: JSON.parse(JSON.stringify(initForm))
    };
  },
  watch: {
    timeCount(val) {
      if (val == loopCount) {
        this._clearTimer();
        this.form.dataStatus = 0;
        this._configSave();
      }
    }
  },
  created() {
    this._configGet();
  },
  mounted() {},
  methods: {
    handleReset() {
      this.$refs.form.resetFields();
      this.form.dataStatus = null;
      this._clearTimer();
    },
    handleCheckboxChange() {},
    handleTabClick(tab, event) {
      console.log(tab, event);
    },
    // 确定
    handleSubmit() {
      this._clearTimer();
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        this._configSave(true);
      });
    },

    async _configSave(s = false) {
      this.timeCount = 0;
      this._clearTimer();
      if (s) {
        this.form.dataStatus = 2;
      }
      const { success, data } = await configSave(this.form);
      if (success) {
        this.toast('保存成功', 'success');
      }
      if (data.dataStatus == 2) {
        this.form.dataStatus = data.dataStatus;
        this._loopDataBaseLoopConnect();
      } else {
        this.form = { ...this.form, ...data };
      }
    },
    async _configGet() {
      this._clearTimer();
      const { data } = await configGet(this.form);
      if (JSON.stringify(data) != '{}') {
        if (this.tmpShowCount == 1) {
          this.form = { ...this.form, ...data };
          this.tmpShowCount++;
        }
        if (data.dataStatus == 2) {
          this.form.dataStatus = data.dataStatus;
          this._loopDataBaseLoopConnect();
        } else {
          this.form = { ...this.form, ...data };
        }
      }
    },
    // 轮询连接
    _loopDataBaseLoopConnect() {
      if (this.form.dataStatus == 0) {
        this.toast('数据库连接失败', 'warning');
        this._clearTimer();
        return;
      }
      if (this.form.dataStatus == 1) {
        this.toast('数据库连接成功', 'success');
        this._clearTimer();
        return;
      }
      this.timer = setInterval(() => {
        this.timeCount++;
        this.timeCount <= loopCount && this._configGet();
      }, 10000);
    },
    _clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    }
  },
  destroyed() {
    this._clearTimer();
    console.log('我走了');
  }
};
</script>

<style scoped lang="scss">
.voucher_config_view {
  position: relative;
  height: calc(100vh - 156px);
  background: #fff;
  overflow: auto;
  .tab_box {
    margin: 24px;
    .status_box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 20px 0;
      .name {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        min-width: 130px;
        color: #3d3d3d;
      }
      .status {
        span {
          font-style: 14px;
          &.su {
            color: #67c23a;
          }
          &.lo {
            color: #e6a23c;
          }
          &.wa {
            color: #f56c6c;
          }
        }
      }
    }
  }

  .cus_form {
    .el-form-item {
      margin-bottom: 30px;
      .el-input {
        width: 280px;
      }
    }
  }
  .tpl_box {
    background: #fafcff;
    padding: 24px;
    .tip {
      color: #909399;
      font-size: 14px;
      margin-top: 16px;
    }
  }
  .mt_50 {
    margin-top: 50px !important;
  }
  .mb_24 {
    margin-bottom: 24px;
  }
  .la_W {
    display: inline-block;
    width: 110px;
    font-size: 13px;
    margin-right: 10px;
    text-align: right;
    color: #303133;
  }
}
</style>
